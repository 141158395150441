<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 280px; margin-right: 20px;">
      <div class="head-container">
        <el-input v-model="disName" clearable placeholder="ERP编号/名称搜索" prefix-icon="el-icon-search" style="width: 100%; margin-right: 0 !important;" class="filter-item" @keyup.enter.native="toQueryDis">
          <el-button slot="append" icon="el-icon-search" @click.stop="toQueryDis"></el-button>
        </el-input>
      </div>
      <tree-picker v-if="disName" key="queryed" url="api/distributor/getSubById" :picker="false" :params="{'disName':disName}" valueField="entId" parentField="parentEntId" style="width: 100%;" @change="distributorChange" />
      <tree-picker v-else key="lazyed" url="api/distributor/getSubById" :picker="false" :lazy="true" valueField="entId" parentField="parentEntId" style="width: 100%;" @change="distributorChange" />
    </div>
    <div class="flex" style="overflow: hidden;">
      <!--工具栏-->
      <div class="head-container">
        <el-input v-model="query.brandName" clearable placeholder="输入品牌名称搜索" style="width: 160px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.categoryName" clearable placeholder="输入分类名称搜索" style="width: 160px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.code" clearable placeholder="商品编码" style="width: 160px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-input v-model="query.name" clearable placeholder="商品名称" style="width: 160px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-radio-group v-model="query.range" @change="toQuery" class="filter-item">
          <el-radio-button label="all">本级及下级数据</el-radio-button>
          <el-radio-button label="level">仅本级数据</el-radio-button>
        </el-radio-group>
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <!--表格渲染-->
      <el-table v-loading="loading" row-key="id" :data="data" size="small">
        <!-- <el-table-column prop="entName" label="经销商名称" /> -->
        <el-table-column prop="code" label="商品编码" min-width="130" />
        <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
        <el-table-column prop="name" label="商品名称" min-width="240" />
        <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
        <el-table-column prop="brandName" label="品牌名称" width="120" />
        <el-table-column prop="seriesName" label="系列名称" width="120" />
        <el-table-column prop="categoryName" label="分类名称" width="120" />
        <el-table-column prop="stock" label="库存" width="80" align="right" />
      </el-table>
      <!--分页组件-->
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import { mapGetters } from "vuex";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData, initDict],
  data() {
    return {
      downloadLoading: false,
      height: document.documentElement.clientHeight - 180 + "px;",
      disName: null,
      defaultTree: true,
      depts: [],
      disId: null,
      disId1: null,
      query: {
        range: "all",
        brandName: null,
        categoryName: null,
        code: null,
        name: null,
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  mounted: function () {
    const that = this;
    window.onresize = function temp() {
      that.height = document.documentElement.clientHeight - 180 + "px;";
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/distributor/stock/";
      const sort = "name,asc";
      const query = this.query;
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        entId: this.disId ? this.disId : this.user.entId,
      };
      if (query.name) {
        this.params["name"] = query.name;
      }
      if (query.brandName) {
        this.params["brandName"] = query.brandName;
      }
      if (query.categoryName) {
        this.params["categoryName"] = query.categoryName;
      }
      if (query.code) {
        this.params["code"] = query.code;
      }
      if (query.range) {
        this.url += query.range;
      }
      return true;
    },
    toQueryDis() {
      if (this.disName) {
        this.defaultTree = false;
      } else {
        this.defaultTree = true;
      }
    },
    distributorChange(data) {
      this.disId = data.entId;
      this.init();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/distributor/stock/download", this.params)
        .then((result) => {
          downloadFile(result, "经销商库存", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>